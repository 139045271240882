import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getQuestionTopic, createTopic, deleteTopic, updateTopic } from '../../pages/core/topicRequest';

export const getQuestionTopicThunk = createAsyncThunk("topic/getQuestionTopicThunk", async (id) => {
    const response = await getQuestionTopic(id);
    return response.data.data;
});

export const createTopicThunk = createAsyncThunk("topic/createTopicThunk", async (data) => {
    const response = await createTopic(data);
    return response.data.data;
});

export const deleteTopicThunk = createAsyncThunk("topic/deleteTopicThunk", async (id) => {
    const response = await deleteTopic(id);
    return id;
});

export const updateTopicThunk = createAsyncThunk("topic/updateTopicThunk", async (data) => {
    const response = await updateTopic(data);
    return response.data.data;
});

export const topicSlice = createSlice({
    name: "topic",
    initialState: {
        selectedProduct: {},
        topics: []
    },
    reducers: {
        setSelectedProduct: (state, action) => {
            state.selectedProduct = action.payload;
            localStorage.setItem("selectedProduct", JSON.stringify(action.payload));
        },
        setTopics: (state, action) => {
            state.topics = action.payload;
        },
        clearStore: (state) => {
            state.topics = [];
            state.selectedProduct = {};
            localStorage.removeItem("selectedProduct");
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getQuestionTopicThunk.fulfilled, (state, action) => {
                state.topics = action.payload;
            })
            .addCase(createTopicThunk.fulfilled, (state, action) => {
                state.topics = [...state.topics, action.payload];
            })
            .addCase(deleteTopicThunk.fulfilled, (state, action) => {
                const index = state.topics.findIndex(item => item.id === action.payload);
                state.topics.splice(index, 1);
            })
            .addCase(updateTopicThunk.fulfilled, (state, action) => {
                const index = state.topics.findIndex(item => item.id === action.payload.id);
                state.topics[index] = action.payload;
            })
    }
});

export const getTopicTitleById = (state, id) => state.topic.topics.find(item => item.id === parseInt(id));

export const { setSelectedProduct, setTopics, clearStore, setStatus } = topicSlice.actions;
export default topicSlice.reducer;