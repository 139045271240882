import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router';
import { getQuestionThunk, setSelectedTopic, deleteQuestionThunk, } from '../../redux/question/questionSlice';
import { getTopicTitleById } from '../../redux/topic/topicSlice';
import CreateQuestion from './CreateQuestion';
import UpdateQuestion from './UpdateQuestion';
import { DeleteModal } from '../components/DeleteModal';
import UploadFile from './UploadFile';
import { KTIcon } from '../../../_metronic/helpers';
import moment from 'moment';

const Questions = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const match = useMatch('/questions/:id');
    const dispatch = useDispatch();
    const questions = useSelector((state) => state.question.questionList);
    let topic = useSelector((state) => getTopicTitleById(state, match.params.id));
    const selectedProduct = JSON.parse(localStorage.getItem('selectedProduct')).title;
    const [select, setSelect] = useState(topic);
    const [sortBy, setSortBy] = useState(null);
    const [sortCount, setSortCount] = useState(0);
    const [sortDirection, setSortDirection] = useState('desc');

    useEffect(() => {
        if (match) {
            dispatch(getQuestionThunk(match.params.id));
        }

        let localTopic = JSON.parse(localStorage.getItem('selectedTopic'));

        if (topic) {
            dispatch(setSelectedTopic(topic));
        }
        else if (localTopic) {
            dispatch(setSelectedTopic(localTopic));
            setSelect(localTopic);
        }

    }, []);

    const handleDelete = (id) => {
        dispatch(deleteQuestionThunk(id));
    };

    const filteredQuestions = questions.filter((question) => {
        return question.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
    }
    );


    const sortedQuestions = filteredQuestions.sort((a, b) => {
        if (sortBy === 'title') {
            return a.title.localeCompare(b.title);
        } else if (sortBy === 'point') {
            console.log(a.point - b.point);
            return a.point - b.point;
        } else if (sortBy === 'created_at') {
            return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
        } else if (sortBy === 'updated_at') {
            //a.update_at ve b.update_at string olarak geliyor. moment ile date objesine çevirip compare ediyoruz.
            const dateStringA = moment(a.updated_at, "DD.MM.YYYY HH:mm:ss");
            const dateStringB = moment(b.updated_at, "DD.MM.YYYY HH:mm:ss");
            return new Date(Date.parse(dateStringA)).getTime() - new Date(Date.parse(dateStringB)).getTime();
        } else if (sortBy === 'image') {
            console.log(a.image, b.image);
            if (a.image && b.image === null) {
                return -1; // a.image, b.image'dan daha büyük olduğu kabul edilir
            } else if (a.image === null && b.image) {
                return 1; // a.image, b.image'dan daha küçük olduğu kabul edilir
            } else {
                return 0; // a.image ve b.image eşit kabul edilir
            }
        } else {
            return 0;
        }

    });

    const handleSort = (key) => {
        if (sortBy === key) {
            setSortCount(sortCount + 1);
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');

        } else {
            setSortCount(0);
            setSortDirection("desc");
        }
        setSortBy(key);
    };

    const reversedQuestions = sortedQuestions;

    if (sortCount % 2 === 1) {
        reversedQuestions.reverse();
    }

    return (
        <>
            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>{selectedProduct} - {select && select.title}</span>
                        {questions.length > 0 ? (
                            <span className='text-muted mt-1 fw-semibold fs-7'> Soru Sayısı {questions && questions.length} </span>)
                            : (<div className="text-center">
                                <span className='text-muted mt-1 fw-semibold fs-7'>Soru Yok</span>
                            </div>)}
                    </h3>
                    <div className='card-toolbar'>
                        <input
                            type='text'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder='Soru Ara...'
                            className='form-control me-5 search-bar'
                        />
                        <CreateQuestion />
                    </div>
                </div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive' style={{ maxHeight: "72vh", overflowY: "scroll" }}>
                        {questions.length > 0 ? (
                            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        <th className='w-25px'>
                                        </th>
                                        <th className='min-w-150px' onClick={() => handleSort("title")}>Soru {sortBy === 'title' ? (
                                            sortDirection === 'asc' ? (
                                                <KTIcon iconName='arrow-down' className='fs-3' />
                                            ) : (
                                                <KTIcon iconName='arrow-up' className='fs-3' />
                                            )
                                        ) : (
                                            <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                        )}</th>
                                        <th className='min-w-140px'></th>
                                        <th className='min-w-120px' onClick={() => handleSort("image")}>Görsel {sortBy === 'image' ? (
                                            sortDirection === 'asc' ? (
                                                <KTIcon iconName='arrow-up' className='fs-3' />
                                            ) : (
                                                <KTIcon iconName='arrow-down' className='fs-3' />
                                            )
                                        ) : (
                                            <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                        )}</th>
                                        <th className='min-w-120px text-center' onClick={() => handleSort("point")}>Puan {sortBy === 'point' ? (
                                            sortDirection === 'asc' ? (
                                                <KTIcon iconName='arrow-down' className='fs-3' />
                                            ) : (
                                                <KTIcon iconName='arrow-up' className='fs-3' />
                                            )
                                        ) : (
                                            <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                        )}</th>
                                        <th className='min-w-120px' onClick={() => handleSort("created_at")}>Oluşturulma Tarihi {sortBy === 'created_at' ? (
                                            sortDirection === 'asc' ? (
                                                <KTIcon iconName='arrow-down' className='fs-3' />
                                            ) : (
                                                <KTIcon iconName='arrow-up' className='fs-3' />
                                            )
                                        ) : (
                                                <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                        )}</th>
                                        <th className='min-w-120px' onClick={() => handleSort("updated_at")}>Güncellenme Tarihi {sortBy === 'updated_at' ? (
                                            sortDirection === 'asc' ? (
                                                <KTIcon iconName='arrow-down' className='fs-3' />
                                            ) : (
                                                <KTIcon iconName='arrow-up' className='fs-3' />
                                            )
                                        ) : (
                                                <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                        )}</th>
                                        <th className='min-w-100px text-end'>Düzenle</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reversedQuestions.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                </td>
                                                <td>
                                                    <span className='d-inline-block text-truncate text-dark fw-bold fs-6' style={{ maxWidth: "20rem" }}>
                                                        {item.title}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className='text-dark fw-bold fs-6'></span>
                                                </td>
                                                <td>
                                                    {
                                                        item.image ? <span className="badge badge-light-success">Var</span> : <span className="badge badge-light-danger">Yok</span>
                                                    }
                                                </td>
                                                <td className='text-dark text-center fw-bold'>
                                                    <span className='text-dark fw-bold d-block mb-1 fs-6'>{item.point}</span>
                                                </td>
                                                <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                    <span className='text-dark fw-bold d-block mb-1 fs-6'>
                                                        {item.created_at}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className='text-dark fw-bold d-block mb-1 fs-6'>{item.updated_at} </span>
                                                </td>
                                                <td className='text-end'>
                                                    {
                                                        filteredQuestions.length > 0 && <UpdateQuestion topicId={select.id} item={item} />
                                                    }
                                                    <DeleteModal id={item.id} modalTitle={"Soruyu Sil"} modalContent={"Soruyu silmek istediğinize emin misiniz ?"} onDelete={handleDelete} />
                                                </td>
                                            </tr>
                                        ))

                                    }
                                </tbody>
                                {/* end::Table body */}
                            </table>)
                            : (
                                <div className="text-center mb-5">
                                    <span className='text-muted mt-1 fw-semibold fs-3'>Soru Yok</span>
                                </div>
                            )
                        }
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>

        </>
    )

}

export { Questions }