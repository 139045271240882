import { useEffect, useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { useDispatch } from 'react-redux';
import { KTIcon } from '../../../_metronic/helpers';
import { updateUserThunk } from '../../redux/user/userSlice';


function UpdateUser({ item }) {
  const [nameUpdated, setNameUpdated] = useState(item.name);
  const [emailUpdated, setEmailUpdated] = useState(item.email);
  const [passwordUpdated, setPasswordUpdated] = useState("");
  const [roleUpdated, setRoleUpdated] = useState(item.role);

  const dispatch = useDispatch();

  useEffect(() => {
    setNameUpdated(item.name);
    setEmailUpdated(item.email);
    setRoleUpdated(item.role);
  }, [item]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(updateUserThunk({
      id: item.id,
      name: nameUpdated,
      email: emailUpdated,
      password: passwordUpdated === "" ? null : passwordUpdated,
      role: roleUpdated }));
  }

  const handleRequired = () => {
    if (nameUpdated === '' || emailUpdated === '' || roleUpdated === '') {
      return true;
    }

    return false;
  }

  return (
    <>
      <button
        type="button"
        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
        data-bs-toggle="modal"
        data-bs-target={`#kt_modal_user_update_${item.id}`}
      >
        <KTIcon iconName='pencil' className='fs-3' />
      </button>

      <div className="modal fade" tabIndex={-1} id={`kt_modal_user_update_${item.id}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Kullanıcı</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <form className="mb-2" onSubmit={handleSubmit}>
                <label className="form-label text-start d-block">Ad Soyad</label>
                <input
                  value={nameUpdated}
                  onChange={(e) => setNameUpdated(e.target.value)}
                  type="text"
                  className={`form-control mb-5`}
                  placeholder="Ad Soyad Giriniz"
                  required
                />
                <label className="form-label text-start d-block">E-Mail</label>
                <input
                  value={emailUpdated}
                  onChange={(e) => setEmailUpdated(e.target.value)}
                  type="email"
                  className={`form-control mb-5`}
                  placeholder="E-Mail Giriniz"
                  required
                />
                <label className="form-label text-start d-block">Şifre</label>
                <input
                  value={passwordUpdated}
                  onChange={(e) => setPasswordUpdated(e.target.value)}
                  type="text"
                  className={`form-control mb-5`}
                  placeholder="Şifre 6 haneden uzun olmalıdır"
                  required
                />
                <label className="form-label text-start d-block">Rol</label>
                <select className="form-select" aria-label="Select example" value={roleUpdated} onChange={e => setRoleUpdated(e.target.value)}>
                  <option value="">Rol seçin</option>
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Kapat
              </button>
              <button type="button" className="btn btn-primary" onClick={(e) => handleSubmit(e)} data-bs-dismiss="modal" disabled={handleRequired()}>
                Oluştur
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateUser