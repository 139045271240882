import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { KTSVG } from "../../../_metronic/helpers";

import { useSelector } from 'react-redux';

import { createTopicThunk, setSelectedProduct, getQuestionTopicThunk } from '../../redux/topic/topicSlice';

function DashboardCreateModal() {
    const [title, setTitle] = useState('');
    const [duration, setDuration] = useState('');
    const [questionCount, setQuestionCount] = useState('');
    const products = useSelector((state) => state.products.products);
    const [select, setSelect] = useState("");
    const selectedProduct = useSelector((state) => state.topic.selectedProduct);

    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (selectedProduct.title === select) {
            await dispatch(createTopicThunk({ title, duration, questionCount, categoryId: selectedProduct.id }));
        }
        else {
            const product = products.filter(p => p.title == select)[0];
            await dispatch(setSelectedProduct(product));
            await dispatch(getQuestionTopicThunk(product.id));
            await dispatch(createTopicThunk({ title, duration, questionCount, categoryId: product.id }));
            window.location.reload();
        }

        setTitle('');
        setDuration('');
        setQuestionCount('');
        setSelect('');
    }
    const handleRequired = () => {
        if (title === '' || duration === '' || questionCount === '' || select === '') {
            return true;
        }
    }

    return (
        <div className="modal fade" tabIndex={-1} id="kt_modal_1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Konu Ekle</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body">
                        <form className="mb-2" onSubmit={handleSubmit}>

                            <label className="form-label">Ürünler</label>
                            <select className="form-select form-select-white mb-5" aria-label="Select example" value={select} onChange={e => setSelect(e.target.value)}>
                                <option value="">Ürün Seçiniz</option>
                                {products && products.map((product) => (
                                    <option key={product.id} value={product.title}>{product.title}</option>
                                ))}
                            </select>

                            <label className="form-label">Konu Başlığı</label>
                            <input
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                type="text"
                                className="form-control mb-5"
                                placeholder="Konu Başlığı Giriniz"

                            />
                            <label className="form-label">Süre</label>
                            <input
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                                type="number"
                                className="form-control mb-5"
                                placeholder="0"
                                min={0}
                            />
                            <label className="form-label ">Soru Sayısı</label>
                            <input
                                value={questionCount}
                                onChange={(e) => setQuestionCount(e.target.value)}
                                type="number"
                                className="form-control mb-5"
                                placeholder="0"
                                min={0}
                            />
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            data-bs-dismiss="modal"
                            onClick={() => setTitle('')}
                        >
                            Kapat
                        </button>
                        <button type="button" className="btn btn-primary" onClick={(e) => handleSubmit(e)} data-bs-dismiss="modal" disabled={handleRequired()}>
                            Oluştur
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function CreateTopic() {
    return (
        <div>
            <button type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_1"
            >
                Konu Oluştur
            </button>
            <DashboardCreateModal />
        </div>
    );
}