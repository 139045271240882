import { KTIcon, KTSVG } from '../../../_metronic/helpers';
import { useEffect, useState } from 'react';
import { getUserAnswers } from '../core/userRequest';

export default function UserDetailsModal({ userId, topicId }) {
    const [userAnswers, setUserAnswers] = useState([]);

    useEffect(() => {
        getUserAnswers(userId, topicId)
            .then(res => {
                const copyData = res.data.data.filter(item => item.question !== null && item.userAnswer !== null);
                setUserAnswers(copyData);
            })
            .catch(err => console.log(err))
    }, [userId, topicId])

    return (
        <div>
            <button className='btn btn-icon btn-bg-light btn-active-color-success btn-sm'
                data-bs-toggle="modal"
                data-bs-target={`#kt_modal_user_${userId}`}
            >
                <KTIcon iconName='eye' className='fs-3' />
            </button>
            <div className="modal fade" tabIndex={-1} id={`kt_modal_user_${userId}`}>
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Kullanıcı Cevapları</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "72vh", overflowY: "scroll" }}>
                            <table className="table table-row-dashed table-row-gray-300 gy-7">
                                <thead>
                                    <tr className="fw-bolder fs-6 text-gray-800">
                                        <th className='text-start w-1/2'>Soru</th>
                                        <th className='text-center'>Verilen Cevap</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userAnswers.map((answer, index) => (
                                            <tr key={index}>
                                                <td className='text-start align-middle'>{answer.question.title}</td>
                                                <td className='text-center align-middle'>
                                                    <span className={`badge my-auto badge-light-${answer.userAnswer.is_correct === 1 ? "success" : "danger"}`}
                                                    >
                                                        {answer.userAnswer.title}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}