import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAllUsers, createUser, deleteUser, updateUser } from "../../pages/core/userRequest";

export const getAllUserThunk = createAsyncThunk("users/getAllUsers", async () => {
    const response = await getAllUsers();
    return response.data.data;
});

export const createUserThunk = createAsyncThunk("users/createUser", async (user) => {
    const response = await createUser(user);
    return response.data.data;
});

export const deleteUserThunk = createAsyncThunk("users/deleteUser", async (id) => {
    const response = await deleteUser(id);
    return id;
});

export const updateUserThunk = createAsyncThunk("users/updateUser", async (user) => {
    const response = await updateUser(user);
    return response.data.data;
});

export const userSlice = createSlice({
    name: "users",
    initialState: {
        userList: [],
        status: "idle"
    },
    reducers: {
        setUsers: (state, action) => {
            state.userList = action.payload;
        },
        deleteUserInStore: (state, action) => {
            const index = state.userList.findIndex(item => item.id === action.payload);
            state.userList.splice(index, 1);
        },
        updateUserInStore: (state, action) => {
            const index = state.users.findIndex(item => item.id === action.payload.id);
            state.users[index] = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getAllUserThunk.fulfilled, (state, action) => {
                state.userList = action.payload;
                state.status = "success";
            })
            .addCase(createUserThunk.fulfilled, (state, action) => {
                state.userList.push(action.payload);
                state.status = "success";
            })
            .addCase(deleteUserThunk.fulfilled, (state, action) => {
                const index = state.userList.findIndex(item => item.id === action.payload);
                state.userList.splice(index, 1);
                state.status = "success";
            })
            .addCase(updateUserThunk.fulfilled, (state, action) => {
                const index = state.userList.findIndex(item => item.id === action.payload.id);
                state.userList[index] = action.payload;
                state.status = "success";
            })
        }
});

export const { setUsers, deleteUserInStore, updateUserInStore } = userSlice.actions;
export default userSlice.reducer;