import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { KTSVG, KTIcon } from "../../../_metronic/helpers";
import { updateTopicThunk } from '../../redux/topic/topicSlice';

function UpdateTopic({ id, title, duration, questionCount, categoryId }) {
    const [questionCountUpdate, setQuestionCountUpdate] = useState(questionCount);
    const [durationUpdate, setDurationUpdate] = useState(duration);
    const [titleUpdate, setTitleUpdate] = useState(title);

    const dispatch = useDispatch();

    useEffect(() => {
        setQuestionCountUpdate(questionCountUpdate);
        setDurationUpdate(duration);
        setTitleUpdate(title);
    }, [questionCount, duration, title]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await dispatch(updateTopicThunk({ id, title: titleUpdate, duration: durationUpdate, questionCount: questionCountUpdate, categoryId }));
    }

    const handleRequired = () => {
        if (questionCountUpdate === '' || durationUpdate === '' || titleUpdate === '') {
            return true;
        }
    }
    
    return (
        <>
            <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                type="button"
                data-bs-toggle="modal"
                data-bs-target={`#kt_modal_update_${id}`}
            >
                <KTIcon iconName='pencil' className='fs-3' />

            </button>
            <div className="modal fade" tabIndex={-1} id={`kt_modal_update_${id}`}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Konuyu Güncelle</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <form className="mb-2" onSubmit={handleSubmit}>

                                <label className="form-label text-start d-block">Konu</label>
                                <input
                                    value={titleUpdate}
                                    onChange={(e) => setTitleUpdate(e.target.value)}
                                    type="text"
                                    className="form-control mb-5"
                                    placeholder="Konu Adı Giriniz"

                                />
                                <label className="form-label text-start d-block">Süre</label>
                                <input
                                    value={durationUpdate}
                                    onChange={(e) => setDurationUpdate(e.target.value)}
                                    type="number"
                                    className="form-control mb-5"
                                    placeholder="0"
                                    min={0}
                                />
                                <label className="form-label text-start d-block ">Soru Sayısı</label>
                                <input
                                    value={questionCountUpdate}
                                    onChange={(e) => setQuestionCountUpdate(e.target.value)}
                                    type="number"
                                    className="form-control mb-5"
                                    placeholder="0"
                                    min={0}
                                />
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                            >
                                Kapat
                            </button>
                            <button type="button" className="btn btn-primary" onClick={(e) => handleSubmit(e)} data-bs-dismiss="modal" disabled={handleRequired()}>
                                Güncelle
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateTopic