export default function Item({ index, data, onDelete, onCheck, onChangeInput }) {
    return (
        <div className="row align-items-center mb-4">
            <div className="col-1">
                <label className="checkbox btn-success">
                    <input type="checkbox" checked={data.is_correct == 1 ? true : false} className="form-check-input" onChange={e => onCheck(index, e.target.value)} />
                </label>
            </div>
            <div className="col-9">
                <input type="text" value={data.title} onChange={e => onChangeInput(index, e.target.value)} className="form-control h-100" placeholder="Şıkkı Giriniz" />
            </div>
            <div className="col-2 text-center">
                <button
                    type="button"
                    className="btn fw-bold btn-danger px-5" onClick={() => onDelete(index)}>
                    <i className="la la-trash fs-2"></i>Sil
                </button>
            </div>
        </div>
    );
}