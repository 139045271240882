import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getQuestionByTopic, createQuestion, deleteQuestion, updateQuestion, deleteQuestionImage } from "../../pages/core/questionRequest";

export const getQuestionThunk = createAsyncThunk("question/getQuestionThunk", async (topicId) => {
    const response = await getQuestionByTopic(topicId);
    return response.data.data;
});

export const createQuestionThunk = createAsyncThunk("question/createQuestionThunk", async (question) => {
    const response = await createQuestion(question);
    return response.data.data;
});

export const deleteQuestionThunk = createAsyncThunk("question/deleteQuestionThunk", async (questionId) => {
    const response = await deleteQuestion(questionId);
    return questionId;
});

export const updateQuestionThunk = createAsyncThunk("question/updateQuestionThunk", async (question) => {
    const response = await updateQuestion(question);
    return response.data.data;
});

export const deleteQuestionFileThunk = createAsyncThunk("question/deleteQuestionFileThunk", async (fileId) => {
    const response = await deleteQuestionImage(fileId);
    return fileId;
});

export const questionSlice = createSlice({
    name: "question",
    initialState: {
        questionList: [],
        selectedTopic: {}
    },
    reducers: {
        setQuestionList: (state, action) => {
            state.questionList = action.payload;
        },
        setSelectedTopic: (state, action) => {
            localStorage.setItem("selectedTopic", JSON.stringify(action.payload));
            state.selectedTopic = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getQuestionThunk.fulfilled, (state, action) => {
                state.questionList = action.payload;
            })
            .addCase(createQuestionThunk.fulfilled, (state, action) => {
                state.questionList.push(action.payload);
            })
            .addCase(deleteQuestionThunk.fulfilled, (state, action) => {
                const index = state.questionList.findIndex(item => item.id === action.payload);
                state.questionList.splice(index, 1);
            })
            .addCase(updateQuestionThunk.fulfilled, (state, action) => {
                const index = state.questionList.findIndex(item => item.id === action.payload.id);
                state.questionList[index] = action.payload;
            })
            .addCase(deleteQuestionFileThunk.fulfilled, (state, action) => {
                const index = state.questionList.findIndex(item => item.id === action.payload);
                state.questionList[index].image = null;
            })
    }
});

export const { setQuestionList, setSelectedTopic } = questionSlice.actions;
export default questionSlice.reducer;
