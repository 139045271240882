import { createRoot } from 'react-dom/client'
// Axios
import axios from 'axios'
// Apps
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'

import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider, setupAxios } from './app/modules/auth'

import { QueryClient, QueryClientProvider } from 'react-query'

import { Provider } from 'react-redux'
import store from './app/redux/store'

setupAxios(axios)

const queryClient = new QueryClient()

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </QueryClientProvider>
    </Provider>
  )
}

/* 
 Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
*/
