import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { KTSVG } from "../../../_metronic/helpers";

import { createProductThunk } from "../../redux/product/productSlice";

export default function CreateProduct() {
    const [title, setTitle] = useState('');
    const [file, setFile] = useState("");
    const imageRef = useRef();

    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('title', title);
        formData.append('icon', file);

        dispatch(createProductThunk(formData));
        setTitle('');
    }

    const handleFile = (e) => {
        const uploadFile = e.target.files[0];

        if (uploadFile && uploadFile.size > 2 * 1024 * 1024) {
            alert('Dosya boyutu 2MB den büyük olamaz.');
        }   
        else {
            setFile(uploadFile);
        }

        imageRef.current.value = "";
    }

    return (
        <div>
            <button type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_1"
            >
                Ürün Oluştur
            </button>
            <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Ürün</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <form className="mb-2" onSubmit={handleSubmit}>
                                <label className="form-label">Ürün Adı</label>
                                <input
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    type="text"
                                    className={`form-control`}
                                    placeholder="Ürün Adı Giriniz"
                                    required
                                />
                                <label className="form-label mt-5">İkon Ekle</label>
                                <div className="mb-3">
                                    <input ref={imageRef} onChange={handleFile} className="form-control" type="file" id="formFile" accept="image/*" />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                onClick={() => setTitle('')}
                            >
                                Kapat
                            </button>
                            <button type="button" className="btn btn-primary" onClick={(e) => handleSubmit(e)} data-bs-dismiss="modal" disabled={title === ""}>
                                Oluştur
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}