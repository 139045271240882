import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import Users from '../pages/users/Users'
import { ProductTable } from '../pages/products/ProductTable'
import { Questions } from '../pages/questions/Questions'
import { Topics } from '../pages/topics/Topics'
import LeaderBoard  from '../pages/leaderboard/LeaderBoard'
const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/products' />} />
        {/* Pages */}
        <Route path='users' element={<Users />} />
        <Route path='products' element={<ProductTable />} />
        <Route path='topics' element={<Topics />} />
        <Route path='questions/:id' element={<Questions />} />
        <Route path='leaderboard' element={<LeaderBoard />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export { PrivateRoutes }
