import { KTIcon, KTSVG } from '../../../_metronic/helpers';

export function DeleteModal({ id, modalTitle, modalContent, onDelete }) {
    return (
        <>
            <button className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                data-bs-toggle="modal"
                data-bs-target={`#kt_modal_delete_modal_${id}`}
            >
                <KTIcon iconName='trash' className='fs-3' />
            </button>
            <div className="modal fade" tabIndex={-1} id={`kt_modal_delete_modal_${id}`}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{modalTitle}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body text-start">
                            <p className='form-label'>{modalContent}</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                            >
                                Kapat
                            </button>
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => onDelete(id)}>
                                Sil
                            </button>
                        </div>
                    </div>
                </div>
            </div></>
    );
}