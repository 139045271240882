import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { DeleteModal } from '../components/DeleteModal';
import CreateTopic from './CreateTopic';
import UpdateTopic from './UpdateTopic';
import moment from 'moment';
import { getQuestionTopicThunk, setSelectedProduct, deleteTopicThunk } from '../../redux/topic/topicSlice';
import { KTIcon } from '../../../_metronic/helpers'; 
import { SelectProduct } from './SelectProduct';

const Topics = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState(null);
    const [sortCount, setSortCount] = useState(0);
    const [sortDirection, setSortDirection] = useState('desc');
    const topics = useSelector((state) => state.topic.topics);
    const dispatch = useDispatch();

    useEffect(() => {
        const localData = JSON.parse(localStorage.getItem("selectedProduct"));
        if (topics.length === 0 && localData) {
            dispatch(setSelectedProduct(localData));
            dispatch(getQuestionTopicThunk(localData.id));
        }
    }, []);

    const handleDelete = async (id) => {
        await dispatch(deleteTopicThunk(id));
    };

    const filteredTopics = topics.filter((topic) =>
        topic.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    );

    const sortedTopics = filteredTopics.slice().sort((a, b) => {
        // A'dan Z'ye ve Z'den A'ya sıralama
        if (sortBy === 'title') {
            return a.title.localeCompare(b.title);
        }else if (sortBy === 'question_count') {
            return a.question_count - b.question_count;
        } else if (sortBy === -'duration') {
        
            return a.duration - b.duration;
        }else if (sortBy === 'created_at') {
            return new Date(a.created_at) - new Date(b.created_at);
        } else if (sortBy === 'updated_at') {
            const dateStringA = moment(a.updated_at, "DD.MM.YYYY HH:mm:ss");
            const dateStringB = moment(b.updated_at, "DD.MM.YYYY HH:mm:ss");
            return new Date(Date.parse(dateStringA)).getTime() - new Date(Date.parse(dateStringB)).getTime();
        }else {
            return 0;
    }
    });

    const handleSort = (key) => {
        if (sortBy === key) {
            setSortCount(sortCount + 1);
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortCount(0);
            setSortDirection("desc");
        }
        setSortBy(key);
    };

    const reversedTopics = sortedTopics;

    if (sortCount % 2 === 1) {
        reversedTopics.reverse();
    }

    return (
        <>
            <SelectProduct />
            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Konular</span>
                        {topics.length > 0 ? (
                            <span className='text-muted mt-1 fw-semibold fs-7'>Konu Sayısı {topics.length} </span>
                        ) : (
                            <div className="text-center">
                                <span className='text-muted mt-1 fw-semibold fs-7'>Konu Yok</span>
                            </div>
                        )
                        }
                    </h3>
                    <div className='card-toolbar'>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Konu Ara..."
                            className='form-control me-5 search-bar'
                        />
                        <CreateTopic />
                    </div>

                </div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive' style={{ maxHeight: "72vh", overflowY: "scroll" }}>
                        {
                            topics.length > 0 ? (
                                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                    {/* begin::Table head */}
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='w-25px'></th>
                                            <th className='min-w-150px' onClick={() => handleSort('title')}>
                                                Konu
                                                {sortBy === 'title' ? (
                                                    sortDirection === 'asc' ? (
                                                        <KTIcon iconName='arrow-down' className='fs-3' />
                                                    ) : (
                                                        <KTIcon iconName='arrow-up' className='fs-3' />
                                                    )
                                                ) : (
                                                        <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                                )}
                                            </th>
                                            <th className='min-w-140px' onClick={() => handleSort("duration")}>Süre {sortBy === 'duration' ? (
                                                sortDirection === 'asc' ? (
                                                    <KTIcon iconName='arrow-up' className='fs-3' />
                                                ) : (
                                                    <KTIcon iconName='arrow-down' className='fs-3' />
                                                )
                                            ) : (
                                                    <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                            )}</th>
                                            <th className='min-w-120px'></th>
                                            <th className='min-w-120px' onClick={() => handleSort('question_count')}>
                                                Soru Sayısı
                                                {sortBy === 'question_count' ? (
                                                    sortDirection === 'asc' ? (
                                                        <KTIcon iconName='arrow-down' className='fs-3' />
                                                    ) : (
                                                        <KTIcon iconName='arrow-up' className='fs-3' />
                                                    )
                                                ) : (
                                                        <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                                )}
                                            </th>
                                            <th className='min-w-120px' onClick={() => handleSort("created_at")} >Oluşturulma Tarihi
                                                {sortBy === 'created_at' ? (
                                                    sortDirection === 'asc' ? (
                                                        <KTIcon iconName='arrow-up' className='fs-3' />
                                                    ) : (
                                                        <KTIcon iconName='arrow-down' className='fs-3' />
                                                    )
                                                ) : (
                                                        <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                                )}</th>
                                            <th className='min-w-120px' onClick={() => handleSort('update_at')}>Güncellenme Tarihi
                                                {sortBy === 'update_at' ? (
                                                    sortDirection === 'asc' ? (
                                                        <KTIcon iconName='arrow-up' className='fs-3' />
                                                    ) : (
                                                        <KTIcon iconName='arrow-down' className='fs-3' />
                                                    )
                                                ) : (
                                                        <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                                )}</th>
                                            <th className='min-w-100px text-end'>Düzenle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            sortedTopics.map((item, index) => (
                                                <tr key={index}>
                                                    <td></td>
                                                    <td>
                                                        <Link to={`/questions/${item.id}`} className='text-primary fw-bold fs-6'>
                                                            {item.title}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <span className='text-dark fw-bold fs-6'>{item.duration}</span>
                                                    </td>
                                                    <td></td>
                                                    <td className='text-dark fw-bold'>
                                                        <span className='text-dark fw-bold d-block mb-1 fs-6'>{item.question_count}</span>
                                                    </td>
                                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                        <span className='text-dark fw-bold d-block mb-1 fs-6'>
                                                            {item.created_at}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className='text-dark fw-bold d-block mb-1 fs-6'>{item.updated_at}</span>
                                                    </td>
                                                    <td className='text-end'>
                                                        <UpdateTopic id={item.id} title={item.title} duration={item.duration} questionCount={item.question_count} categoryId={item.category_id} />
                                                        <DeleteModal id={item.id} modalTitle={"Konu Sil"} modalContent={"Konuyu silmek istediğinize emin misiniz ?"} onDelete={handleDelete} />
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            ) : (
                                <div className="text-center mb-5">
                                    <span className='text-muted mt-1 fw-semibold fs-3'>Konu Yok</span>
                                </div>
                            )
                        }
                    </div>
                    {/* end::Table container */}
                </div>
            </div>
        </>
    )
}

export { Topics }
