export const BASE_URL = "https://quizapp.ayssoft.com/api/v1";

export const productURL = {
    getAllProducts: `${BASE_URL}/categories/getAllCategories`,
    createProduct: `${BASE_URL}/categories/createCategory`,
    updateProduct: `${BASE_URL}/categories/updateCategory`,
    deleteProduct: `${BASE_URL}/categories/deleteCategory`
}

export const topicURl = {
    getQuestionTopic: `${BASE_URL}/categories/getTopicsByCategoryId`,
    createTopic: `${BASE_URL}/topics/createTopic`,
    updateTopic: `${BASE_URL}/topics/updateTopic`,
    deleteTopic: `${BASE_URL}/topics/deleteTopic`
}

export const questionURL = {
    getQuestionByTopic: `${BASE_URL}/topics/getQuestionsByTopic`,
    createQuestion: `${BASE_URL}/questions/createQuestion`,
    updateQuestion: `${BASE_URL}/questions/updateQuestion`,
    deleteQuestion: `${BASE_URL}/questions/deleteQuestion`,
    deleteQuestionImage: `${BASE_URL}/questions/removeImage`,
}


export const usersURL = {
    getAllUsers: `${BASE_URL}/users/getAllUsers`,
    createUser: `${BASE_URL}/users/createUser`,
    updateUser: `${BASE_URL}/users/updateUser`,
    deleteUser: `${BASE_URL}/users/deleteUser`,
    getLeaderBoard: `${BASE_URL}/analytics/getTopicScore`,
    getUserAnswers: `${BASE_URL}/analytics/getTopicAnswersByUserAndTopic`,
}
