import { productURL } from "./url";
import axios from "axios";

export const getAllProducts = () => {
    return axios.get(productURL.getAllProducts);
};

export const  createProduct = (data) => {
    return axios.post(productURL.createProduct, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

export const updateProduct = (id, title) => {
    return axios.post(productURL.updateProduct, { id, title });
}

export const deleteProduct = (id) => {
    return axios.delete(productURL.deleteProduct, { data: { id } });
}