import axios from "axios";
import { questionURL } from "./url";

export const getQuestionByTopic = (id) => {
    return axios.get(`${questionURL.getQuestionByTopic}?id=${id}`);
}

export const createQuestion = (data) => {
    return axios.post(questionURL.createQuestion, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

export const deleteQuestion = (id) => {
    return axios.delete(questionURL.deleteQuestion, { data: { id } });
}

export const updateQuestion = (data) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    return axios.post(questionURL.updateQuestion, data, config);
}

export const deleteQuestionImage = (id) => {
    return axios.post(`${questionURL.deleteQuestionImage}?id=${id}`);
}