import { configureStore } from "@reduxjs/toolkit";
import topicReducer from "./topic/topicSlice";
import productReducer from "./product/productSlice";
import questionReducer from "./question/questionSlice";
import userReducer from "./user/userSlice";

export default configureStore({
    reducer: {
        topic: topicReducer,
        products: productReducer,
        question: questionReducer,
        users: userReducer
    }
});