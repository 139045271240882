/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  return (
    <>
      <SidebarMenuItem to='/users' icon='user' title='Kullanıcılar' fontIcon='bi-layers' />
      <SidebarMenuItem
        to='/products'
        icon='element-11'
        title='Ürünler'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem to='/topics' icon='briefcase' title='Konular' fontIcon='bi-layers' />
      <SidebarMenuItem to='/leaderboard' icon='cup' title='Liderlik Tablosu' fontIcon='bi-layers' />
    </>

  )
}

export {SidebarMenuMain}
