import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import "./style.css";
import moment from 'moment';
import CreateUser from './CreateUser';
import UpdateUser from './UpdateUser';
import { KTIcon } from '../../../_metronic/helpers';
import { getAllUserThunk, deleteUserThunk } from '../../redux/user/userSlice';
import { DeleteModal } from '../components/DeleteModal';

function Users() {
    const [searchTerm, setSearchTerm] = useState('');
    const users = useSelector((state) => state.users.userList);
    const status = useSelector((state) => state.users.status);
    const [sortBy, setSortBy] = useState(null);
    const [sortCount, setSortCount] = useState(0);
    const [sortDirection, setSortDirection] = useState('desc');
    const dispatch = useDispatch();

    useEffect(() => {
        if (status === 'idle' && !users.length) {
            dispatch(getAllUserThunk());
        }
    }, [status, dispatch]);

    const handleDelete = (id) => {
        dispatch(deleteUserThunk(id));
    };

    const filteredUsers = users.filter((user) =>
        user.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        user.email.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        user.role.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    );

    const handleSort = (key) => {
        if (sortBy === key) {
            setSortCount(sortCount + 1);
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortCount(0);
            setSortDirection('desc');
        }
        setSortBy(key);
    };

    const sortedUsers = filteredUsers.slice().sort((a, b) => {
        if (sortBy === 'name') {
            return a.name.localeCompare(b.name);
        } else if (sortBy === 'email') {
            return a.email.localeCompare(b.email);
        } else if (sortBy === 'role') {
            return a.role.localeCompare(b.role);
        } else if (sortBy === 'score') {
            return a.score.scoreTotal - b.score.scoreTotal;
        } else if (sortBy === 'price') {
            return a.score.priceTotal - b.score.priceTotal;
        } else if (sortBy === 'created_at') {
            return new Date(a.created_at) - new Date(b.created_at);
        } else if (sortBy === 'updated_at') {
            const dateStringA = moment(a.updated_at, "DD.MM.YYYY HH:mm:ss");
            const dateStringB = moment(b.updated_at, "DD.MM.YYYY HH:mm:ss");
            return new Date(Date.parse(dateStringA)).getTime() - new Date(Date.parse(dateStringB)).getTime();
        } else {
            return 0;
        }
    });

    const reversedUsers = sortedUsers;

    if (sortCount % 2 === 1) {
        reversedUsers.reverse();    
    }

    return (
        <div className={`card overflow-hidden`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Kullanıcılar </span>
                    {users.length > 0 ? (
                        <span className='text-muted mt-1 fw-semibold fs-7'> Kullanıcı Sayısı {users.length}</span>)
                        : (
                            <div className="text-center">
                                <span className='text-muted mt-1 fw-semibold fs-7'>Kullanıcı Yok</span>
                            </div>
                        )
                    }
                </h3>
                <div className='card-toolbar'>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Ara..."
                        className='form-control me-5 search-bar'
                    />
                    <CreateUser />
                </div>
            </div>
            <div className='card-body py-3' style={{ maxHeight: "78vh", overflowY: "scroll" }}>
                <div className='table-responsive'>
                    {users.length > 0 ? (
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='w-25px cursor-pointer'></th>
                                    <th className='min-w-150px cursor-pointer' onClick={() => handleSort('name')}>
                                        Ad Soyad
                                        {sortBy === 'name' ? (
                                            sortDirection === 'asc' ? (
                                                <KTIcon iconName='arrow-down' className='fs-3' />
                                            ) : (
                                                <KTIcon iconName='arrow-up' className='fs-3' />
                                            )
                                        ) : (
                                                <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                        )}
                                    </th>
                                    <th className='min-w-140px cursor-pointer' onClick={() => handleSort('email')}>
                                        E-mail
                                        {sortBy === 'email' ? (
                                            sortDirection === 'asc' ? (
                                                <KTIcon iconName='arrow-down' className='fs-3' />
                                            ) : (
                                                <KTIcon iconName='arrow-up' className='fs-3' />
                                            )
                                        ) : (
                                                <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                        )}
                                    </th>
                                    <th className='min-w-120px'></th>
                                    <th className='min-w-120px'></th>
                                    <th className='min-w-120px cursor-pointer' onClick={() => handleSort('role')}>
                                        Rol
                                        {sortBy === 'role' ? (
                                            sortDirection === 'asc' ? (
                                                <KTIcon iconName='arrow-up' className='fs-3' />
                                            ) : (
                                                <KTIcon iconName='arrow-down' className='fs-3' />
                                            )
                                        ) : (
                                                <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                        )}
                                    </th>
                                    <th className='min-w-120px cursor-pointer' onClick={() => handleSort('score')}>
                                        Toplam Puan
                                        {sortBy === 'score' ? (
                                            sortDirection === 'asc' ? (
                                                <KTIcon iconName='arrow-down' className='fs-3' />
                                            ) : (
                                                <KTIcon iconName='arrow-up' className='fs-3' />
                                            )
                                        ) : (
                                                <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                        )}
                                    </th>
                                    <th className='min-w-120px cursor-pointer' onClick={() => handleSort('price')}>
                                        Toplam Bakiye
                                        {sortBy === 'price' ? (
                                            sortDirection === 'asc' ? (
                                                <KTIcon iconName='arrow-down' className='fs-3' />
                                            ) : (
                                                <KTIcon iconName='arrow-up' className='fs-3' />
                                            )
                                        ) : (
                                                <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                        )}
                                    </th>
                                    <th className='min-w-120px cursor-pointer' onClick={() => handleSort('created_at')}>
                                        Oluşturulma Tarihi
                                        {sortBy === 'created_at' ? (
                                            sortDirection === 'asc' ? (
                                                <KTIcon iconName='arrow-down' className='fs-3' />
                                            ) : (
                                                <KTIcon iconName='arrow-up' className='fs-3' />
                                            )
                                        ) : (
                                                <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                        )}
                                    </th>
                                    <th className='min-w-120px cursor-pointer' onClick={() => handleSort('updated_at')}>
                                        Güncellenme Tarihi
                                        {sortBy === 'updated_at' ? (
                                            sortDirection === 'asc' ? (
                                                <KTIcon iconName='arrow-down' className='fs-3' />
                                            ) : (
                                                <KTIcon iconName='arrow-up' className='fs-3' />
                                            )
                                        ) : (
                                                <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                        )}
                                    </th>
                                    <th className='min-w-100px text-end'>Düzenle</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    reversedUsers.map((item, index) => (
                                        <tr key={index}>
                                            <td></td>
                                            <td>
                                                <span className='text-dark fw-bold fs-6'>{item.name}</span>
                                            </td>
                                            <td>
                                                <span className='text-dark fw-bold fs-6'>{item.email}</span>
                                            </td>
                                            <td>
                                                <span className='text-dark fw-bold fs-6'></span>
                                            </td>
                                            <td></td>
                                            <td>
                                                <span
                                                    className={`badge badge-light-${item.role === "admin" ? "danger" : "primary"
                                                        }`}
                                                >
                                                    {item.role}
                                                </span>
                                            </td>
                                            <td>
                                                <span className='text-dark fw-bold fs-6'>{item.score.scoreTotal}</span>
                                            </td>
                                            <td>
                                                <span className='text-dark fw-bold fs-6'>{item.score.priceTotal}</span>
                                            </td>
                                            <td>
                                                <span className='text-dark fw-bold d-block mb-1 fs-6'>
                                                    {item.created_at}
                                                </span>
                                            </td>
                                            <td className='text-dark fw-bold'>
                                                {item.updated_at ? item.updated_at : "Güncellenmedi"}
                                            </td>
                                            <td className='text-end'>
                                                <UpdateUser item={item} />
                                                <DeleteModal
                                                    id={item.id}
                                                    modalTitle={"Kullanıcıyı Sil"}
                                                    modalContent={"Kullanıcıyı silmek istediğinize emin misiniz ?"}
                                                    onDelete={handleDelete}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-center mb-5">
                            <span className='text-muted mt-1 fw-semibold fs-3'>Kullanıcı Yok</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Users;
