import axios from "axios";
import { usersURL } from "./url";

export const getAllUsers = () => {
    return axios.get(usersURL.getAllUsers);
}

export const createUser = (data) => {
    return axios.post(usersURL.createUser, data);
}

export const deleteUser = (id) => {
    return axios.delete(usersURL.deleteUser, { data: { id } });
}

export const updateUser = (data) => {
    return axios.put(usersURL.updateUser, data);
}

export const getLeaderBoard = (id) => {
    return axios.get(`${usersURL.getLeaderBoard}?topicId=${id}`);
}

export const getUserAnswers = (userId, topicId) => {
    return axios.get(`${usersURL.getUserAnswers}?userId=${userId}&topicId=${topicId}`);
}