import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllProducts, deleteProduct, createProduct, updateProduct } from '../../pages/core/productRequest';

export const getProductsThunk = createAsyncThunk("products/getProducts", async () => {
    const response = await getAllProducts();
    return response.data.data;
});

export const deleteProductThunk = createAsyncThunk("products/deleteProduct", async (id) => {
    await deleteProduct(id);
    return id;
});

export const createProductThunk = createAsyncThunk("products/createProduct", async (data) => {
    const response = await createProduct(data);
    return response.data.data;
});

export const updateProductThunk = createAsyncThunk("products/updateProduct", async (product) => {
    const response = await updateProduct(product.id, product.title);
    return response.data.data;
});

export const productSlice = createSlice({
    name: "products",
    initialState: {
        products: [],
        status: "idle"
    },
    reducers: {
        setProductList: (state, action) => {
            state.products = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getProductsThunk.fulfilled, (state, action) => {
                state.status = "success";
                state.products = action.payload;
            })
            .addCase(deleteProductThunk.fulfilled, (state, action) => {
                state.status = "success";
                const index = state.products.findIndex(item => item.id === action.payload);
                state.products.splice(index, 1);
            })
            .addCase(createProductThunk.fulfilled, (state, action) => {
                state.products.push(action.payload);
            })
            .addCase(updateProductThunk.fulfilled, (state, action) => {
                const index = state.products.findIndex(item => item.id === action.payload.id);
                state.products[index] = action.payload;
            })
    }
});

export const getProductByTitle = (state, title) => state.products.products.find(item => item.title === title);

export const { setProductList } = productSlice.actions;
export default productSlice.reducer;