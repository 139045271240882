import axios from 'axios'

const API_URL = "https://quizapp.ayssoft.com/api/v1"

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/users/getProfile`
export const LOGIN_URL = `${API_URL}/auth/login`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

export function getUserByToken() {
  return axios.get(GET_USER_BY_ACCESSTOKEN_URL);

}
