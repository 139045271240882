import { useEffect, useState } from 'react';
import { getAllProducts } from '../core/productRequest';
import { getQuestionTopic } from '../core/topicRequest';

export default function SelectHeader({ onSearch }) {
    const [products, setProducts] = useState([]);
    const [selectProduct, setSelectProduct] = useState('');
    const [topics, setTopics] = useState([]);
    const [selectTopic, setSelectTopic] = useState('');

    useEffect(() => {
        if (!products.length) {
            getAllProducts()
                .then((res) => {
                    setProducts(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, []);

    useEffect(() => {
        if (selectProduct) {
            const topicId = products.filter((p) => p.title == selectProduct)[0].id;
            getQuestionTopic(topicId)
                .then((res) => {
                    setTopics(res.data.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [selectProduct]);


    const handleSearch = () => {
        if (selectProduct && selectTopic) {
            const topicId = topics.filter((t) => t.title == selectTopic)[0].id;
            onSearch(topicId);
        }
    };

    const handleChangeProduct = (e) => {
        setSelectProduct(e.target.value);

        if (e.target.value === "") {
            setTopics([]);
            setSelectTopic("");
        }
    };


    return (
        <div className='mb-10 w-100 d-flex'>
            <select className="form-select form-select-white me-5" aria-label="Select example" value={selectProduct} onChange={handleChangeProduct}>
                <option value="">Ürün Seçiniz</option>
                {products.length > 0 && products.map((product) => (
                    <option key={product.id} value={product.title}>{product.title}</option>
                ))}
            </select>
            <select className="form-select form-select-white" aria-label="Select example" value={selectTopic} onChange={(e) => setSelectTopic(e.target.value)}>
                <option value="">Konu Seçiniz</option>
                {topics.length > 0 && topics.map((topic) => (
                    <option key={topic.id} value={topic.title}>{topic.title}</option>
                ))}
            </select>
            <button className='btn btn-primary ms-20' onClick={handleSearch}>Listele</button>
        </div>
    );
}