import { topicURl } from "./url";
import axios from "axios";


export const getQuestionTopic = (id) => {
  return axios.get(`${topicURl.getQuestionTopic}?id=${id}`);
}

export const createTopic = (data) => {
  return axios.post(topicURl.createTopic, data);
}

export const deleteTopic = (id) => {
  return axios.delete(topicURl.deleteTopic, { data: { id } });
}

export const updateTopic = (data) => {
  return axios.post(topicURl.updateTopic, data);
}
