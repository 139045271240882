import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { KTSVG, KTIcon } from "../../../_metronic/helpers";

import "./FormRepeater.css";
import { updateQuestionThunk, deleteQuestionFileThunk } from "../../redux/question/questionSlice";

import FormRepeater from "./FormRepeater";

function UpdateQuestion({ topicId, item }) {
    const [title, setTitle] = useState(item.title);
    const [point, setPoint] = useState(item.point);
    const [answers, setAnswers] = useState(item.answers);
    const dispatch = useDispatch();
    const [file, setFile] = useState(item.image);
    const imageRef = useRef(item.image);

    useEffect(() => {
        setAnswers(item.answers)
    }, []);

    useEffect(() => {
        setTitle(item.title)
        setPoint(item.point)
        setAnswers(item.answers)
    }, [item]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        formData.append("id", parseInt(item.id));

        if (typeof file !== "string" && file !== null) {
            formData.append("image", file);
        }

        formData.append("title", title);
        formData.append("point", parseInt(point));
        formData.append("topicId", parseInt(topicId));


        const answerCopy = [...answers];

        answerCopy.forEach((answer, index) => {
            formData.append(`answers[${index}][id]`, answer.id ?? '');
            formData.append(`answers[${index}][title]`, answer.title);
            formData.append(`answers[${index}][isCorrect]`, answer.is_correct);
        });

        dispatch(updateQuestionThunk(formData));
    }

    const handleRequired = () => {
        if (title === '' || point === '' || answers.length === 0 || !answers.every(x => x.title !== '') || !answers.every(x => x.is_correct !== '') || answers.length < 2) {
            return true;
        }
    }

    const handleFile = (e) => {
        const uploadFile = e.target.files[0];

        if (uploadFile && uploadFile.size > 2 * 1024 * 1024) {
            alert('Dosya boyutu 2MB den büyük olamaz.');
            imageRef.current.value = "";
        }
        else {
            setFile(uploadFile);
        }
    }

    const handleDeleteFile = () => {
        const confirm = window.confirm('Dosyayı silmek istediğinize emin misiniz?');

        if (confirm) {
            dispatch(deleteQuestionFileThunk(item.id));
            setFile('');
            imageRef.current.value = "";
        }
    }

    return (
        <>
            <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                type="button"
                data-bs-toggle="modal"
                data-bs-target={`#kt_modal_update_question${item.id}`}
            >
                <KTIcon iconName='pencil' className='fs-3' />

            </button>
            <div className="modal fade" tabIndex={-1} id={`kt_modal_update_question${item.id}`}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Soruyu Güncellenme</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <form className="mb-2" onSubmit={handleSubmit}>
                                <label className="form-label d-block text-start">Soru Metni</label>
                                <textarea
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    type="textarea"
                                    className="form-control mb-5"
                                    placeholder="Soru Metnini Giriniz"
                                ></textarea>

                                {
                                    file && (
                                        <div className="text-center" style={{ height: "400px" }}>
                                            <img src={typeof file === "object" ? URL.createObjectURL(file) : file} className="w-100 h-100 mb-5" style={{ objectFit: "contain" }} />
                                        </div>
                                    )
                                }

                                <label className="form-label mt-5 d-block text-start">Görsel Ekle</label>
                                <div className="mb-3 d-flex">
                                    <input ref={imageRef} onChange={handleFile} className="form-control" type="file" id="formFile" accept="image/*" />
                                    {
                                        file && <button type="button" className="btn btn-danger ms-5" onClick={() => handleDeleteFile()}>Temizle</button>
                                    }
                                </div>

                                <label className="form-label d-block text-start">Puan</label>
                                <input
                                    value={point}
                                    onChange={(e) => setPoint(e.target.value)}
                                    type="number"
                                    className="form-control mb-7"
                                    placeholder="0"
                                    min={0}

                                />
                                <FormRepeater formData={answers} setFormData={setAnswers} />
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                            >
                                Kapat
                            </button>
                            <button type="button" className="btn btn-primary" onClick={(e) => handleSubmit(e)} data-bs-dismiss="modal" disabled={handleRequired()}>
                                Güncelle
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpdateQuestion