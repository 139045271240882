import { useState } from "react";
import { useDispatch } from "react-redux";
import { KTSVG } from "../../../_metronic/helpers";

import { createUserThunk } from "../../redux/user/userSlice";

export default function CreateUser() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(createUserThunk({ name, email, password, role }));

    setName('');
    setEmail('');
    setPassword('');
    setRole('');
  }

  const handleRequired = () => {
    if (name === '' || email === '' || password === '' || role === '' || password.length < 6) {
      return true;
    }

    return false;
  }

  return (
    <div className="">
      <button type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#kt_modal_1"
      >
        Kullanıcı Oluştur
      </button>
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Kullanıcı</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <form className="mb-2" onSubmit={handleSubmit}>
                <label className="form-label">Ad Soyad</label>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className={`form-control mb-5`}
                  placeholder="Ad Soyad Giriniz"
                  required
                />
                <label className="form-label">E-Mail</label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className={`form-control mb-5`}
                  placeholder="ad.soyad@ayssoft.com"
                  required
                />
                <label className="form-label">Şifre</label>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="text"
                  className={`form-control mb-5`}
                  placeholder="Şifre 6 haneden uzun olmalıdır."
                  required
                />

                <label className="form-label">Rol</label>
                <select className="form-select" aria-label="Select example" value={role} onChange={e => setRole(e.target.value)}>
                  <option value="">Rol seçin</option>
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Kapat
              </button>
              <button type="button" className="btn btn-primary" onClick={(e) => handleSubmit(e)} data-bs-dismiss="modal" disabled={handleRequired()}>
                Oluştur
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
