import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setSelectedProduct } from "../../redux/topic/topicSlice";

import { getProductsThunk } from "../../redux/product/productSlice";
import { getQuestionTopicThunk } from "../../redux/topic/topicSlice";

export const SelectProduct = () => {
    const selectedProduct = useSelector((state) => state.topic.selectedProduct);
    const products = useSelector((state) => state.products.products);
    const [select, setSelect] = useState(selectedProduct.title || "");
    const dispatch = useDispatch();


    //Uygulama başlatıldığında localstorage'dan seçili ürünü alıyoruz.
    useEffect(() => {
        const localData = JSON.parse(localStorage.getItem("selectedProduct"));

        if (!products.length) {
            dispatch(getProductsThunk());
        }

        if (!selectedProduct.title && localData) {
            dispatch(setSelectedProduct(localData));
            setSelect(localData.title);
        }
    }, []);

    //Seçili ürün değiştiğinde selectbox'ı güncelliyoruz.
    useEffect(() => {
        dispatch(getProductsThunk());
        setSelect(selectedProduct.title);
    }, [selectedProduct]);

    const handleSelect = (e) => {
        setSelect(e.target.value);
    };

    const handleSearch = () => {
        if (select) {
            const product = products.filter(p => p.title === select)[0];
            dispatch(setSelectedProduct(product));
            dispatch(getQuestionTopicThunk(product.id));
        }
    };

    return (
        <div className='mb-10 w-100 d-flex'>
            <select className="form-select form-select-white" aria-label="Select example" value={select} onChange={handleSelect}>
                <option value="">Ürün Seçiniz</option>
                {products && products.map((product) => (
                    <option key={product.id} value={product.title}>{product.title}</option>
                ))}
            </select>
            <button className='btn btn-primary ms-20' onClick={handleSearch}>Listele</button>
        </div>
    );
};