import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import CreateProduct from './CreateProduct';
import UpdateProduct from './UpdateProduct';
import { getProductsThunk, deleteProductThunk } from "../../redux/product/productSlice";
import { clearStore } from "../../redux/topic/topicSlice";
import { KTIcon } from "../../../_metronic/helpers";
import { DeleteModal } from "../components/DeleteModal";

import ays from "./ays-ikon-30x30.png";

export function ProductTable() {
    const [searchTerm, setSearchTerm] = useState('');
    const productList = useSelector((state) => state.products.products);
    const status = useSelector((state) => state.products.status);
    const dispatch = useDispatch();
    const [sortBy, setSortBy] = useState(null);
    const [sortCount, setSortCount] = useState(0);
    const [sortDirection, setSortDirection] = useState("desc");

    useEffect(() => {
        if (status === 'idle' && !productList.length) {
            dispatch(getProductsThunk());
        }
    }, [status, dispatch]);

    const handleDelete = async (id) => {
        await dispatch(deleteProductThunk(id));
        dispatch(clearStore());
    }
    
    const filteredProducts = productList.filter((product) =>
        product.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    );

    const handleSort = (key) => {
        if (sortBy === key) {
            setSortCount(sortCount + 1);
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');

        } else {
            setSortCount(0);
            setSortDirection("desc");
        }
        setSortBy(key);
    };

    const sortedProducts = [...filteredProducts].sort((a, b) => {
        if (sortBy === "title") {
            return a.title.localeCompare(b.title);
        } else if (sortBy === "created_at") {
            return a.created_at.localeCompare(b.created_at);
        } else if (sortBy === "updated_at") {
            const dateStringA = moment(a.updated_at, "DD.MM.YYYY HH:mm:ss");
            const dateStringB = moment(b.updated_at, "DD.MM.YYYY HH:mm:ss");
            return new Date(Date.parse(dateStringA)).getTime() - new Date(Date.parse(dateStringB)).getTime();
        }
        return 0;
    });

    const reversedProducts = sortedProducts;

    if (sortCount % 2 === 1) {
        reversedProducts.reverse();
    }

    return (
        <div className={`card overflow-hidden`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Ürünler</span>
                    {productList.length > 0 ? (
                        <span className='text-muted mt-1 fw-semibold fs-7'> Ürün Sayısı {productList.length}</span>)
                        : (
                            <div className="text-center">
                                <span className='text-muted mt-1 fw-semibold fs-7'>Ürün Yok</span>
                            </div>
                        )
                    }
                </h3>
                <div className='card-toolbar'>
                        <input
                            type='text'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder='Ürün Ara...'
                        className='form-control me-5 search-bar'
                        />
                        <CreateProduct />
                </div>
            </div>
            {/* begin::Body */}
            <div className='card-body py-3' style={{ maxHeight: "72vh", overflowY: "scroll" }}>
                <div className='table-responsive'>
                    {productList.length > 0 ? (
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='w-25px'>
                                    </th>
                                    <th className='min-w-150px' onClick={() => handleSort("title")}>Ürün Adı {sortBy === 'title' ? (
                                        sortDirection === 'asc' ? (
                                            <KTIcon iconName='arrow-down' className='fs-3' />
                                        ) : (
                                            <KTIcon iconName='arrow-up' className='fs-3' />
                                        )
                                    ) : (
                                            <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                    )}</th>
                                    <th className='min-w-140px'></th>
                                    <th className='min-w-120px' onClick={() => handleSort("created_at")}>Oluşturulma Tarihi {sortBy === 'created_at' ? (
                                        sortDirection === 'asc' ? (
                                            <KTIcon iconName='arrow-down' className='fs-3' />
                                        ) : (
                                            <KTIcon iconName='arrow-up' className='fs-3' />
                                        )
                                    ) : (
                                            <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                    )}</th>
                                    <th className='min-w-120px' onClick={() => handleSort("updated_at")}>Güncellenme Tarihi {sortBy === 'updated_at' ? (
                                        sortDirection === 'asc' ? (
                                            <KTIcon iconName='arrow-down' className='fs-3' />
                                        ) : (
                                            <KTIcon iconName='arrow-up' className='fs-3' />
                                        )
                                    ) : (
                                            <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                    )}</th>
                                    <th className='min-w-120px'></th>
                                    <th className='min-w-120px'></th>
                                    <th className='min-w-100px text-end'>Düzenle</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    reversedProducts.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <img src={item.icon ? item.icon : ays} alt={item.title} className="rounded-circle" width={30} height={30} />
                                            </td>
                                            <td>
                                                <span className='text-dark fw-bold fs-6'>
                                                    {item.title}
                                                </span>
                                            </td>
                                            <td>
                                                
                                            </td>
                                            <td>
                                                <span className='text-dark fw-bold d-block mb-1 fs-6'>
                                                    {item.created_at}
                                                </span>
                                            </td>
                                            <td className='text-dark fw-bold'>
                                                {item.updated_at}
                                            </td>
                                            <td className='text-dark fw-bold text-hover-primary fs-6'></td>
                                            <td>
                                                <span className='badge badge-light-success'></span>
                                            </td>
                                            <td className='text-end'>
                                                <UpdateProduct id={item.id} title={item.title} />
                                                <DeleteModal id={item.id} modalTitle={"Ürünü Sil"} modalContent={"Ürünü silmek istediğinize emin misiniz ?"} onDelete={handleDelete} />
                                            </td>
                                        </tr>
                                    ))

                                }
                            </tbody>
                        </table>)
                        : (
                            <div className="text-center mb-5">
                                <span className='text-muted mt-1 fw-semibold fs-3'>Ürün Yok</span>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}