import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { KTSVG } from "../../../_metronic/helpers";

import "./FormRepeater.css";
import { createQuestionThunk } from "../../redux/question/questionSlice";

import FormRepeater from "./FormRepeater";

export default function CreateQuestion() {
    const [questionTitle, setQuestionTitle] = useState('');
    const [earned, setEarned] = useState(0);
    const [answers, setAnswers] = useState([]);
    const topic = useSelector(state => state.question.selectedTopic);
    const dispatch = useDispatch();
    const [file, setFile] = useState("");
    const imageRef = useRef();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("image", file);
        formData.append("title", questionTitle);
        formData.append("point", earned);
        formData.append("topicId", topic.id);

        const answerCopy = [...answers];

        answerCopy.forEach((answer, index) => {
            formData.append(`answers[${index}][title]`, answer.title);
            formData.append(`answers[${index}][isCorrect]`, answer.is_correct);
        });

        dispatch(createQuestionThunk(formData));

        setAnswers([])
        setQuestionTitle("")
        setEarned("")
        setFile("")
        imageRef.current.value = "";
    }

    const handleAddAnswer = (newData) => {
        setAnswers(newData);
    }

    const handleRequired = () => {
        if (questionTitle === '' || earned === '' || answers.length === 0 || !answers.every(x => x.title !== '') || !answers.some(x => x.is_correct === 1) || answers.length < 2) {
            console.log("false")
            return true;
        }
    }

    const reset = () => {
        setAnswers([])
        setQuestionTitle("")
        setEarned("")
    }

    const handleFile = (e) => {
        const uploadFile = e.target.files[0];

        if (uploadFile && uploadFile.size > 2 * 1024 * 1024) {
            alert('Dosya boyutu 2MB den büyük olamaz.');
            imageRef.current.value = "";
        }
        else {
            setFile(uploadFile);
        }
    }


    return (
        <div>
            <button type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_create_question"
            >
                Soru Oluştur
            </button>
            <div className="modal fade" tabIndex={-1} id="kt_modal_create_question">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Soru Oluştur</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={reset}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <form className="mb-2" onSubmit={handleSubmit}>
                                <label className="form-label">Soru Metni</label>
                                <textarea
                                    value={questionTitle}
                                    onChange={(e) => setQuestionTitle(e.target.value)}
                                    type="textarea"
                                    className="form-control mb-5"
                                    placeholder="Soru Metnini Giriniz"
                                ></textarea>

                                {
                                    file && (
                                        <div className="text-center" style={{ height: "400px" }}>
                                            <img src={typeof file === "object" ? URL.createObjectURL(file) : file} className="w-100 h-100 mb-5" style={{ objectFit: "contain" }} alt="soru görseli" />
                                        </div>
                                    )
                                }

                                <label className="form-label mt-5">Görsel Ekle</label>
                                <div className="mb-3">
                                    <input ref={imageRef} onChange={handleFile} className="form-control" type="file" id="formFile" accept="image/*" />
                                </div>

                                <label className="form-label">Puan</label>
                                <input
                                    value={earned}
                                    onChange={(e) => setEarned(e.target.value)}
                                    type="number"
                                    className="form-control mb-7"
                                    placeholder="0"
                                    min={0}

                                />
                                <FormRepeater formData={answers} setFormData={handleAddAnswer} />
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                onClick={() => reset()}
                            >
                                Kapat
                            </button>
                            <button type="button" className="btn btn-primary" onClick={(e) => handleSubmit(e)} data-bs-dismiss="modal" disabled={handleRequired()}>
                                Oluştur
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}