import { useState } from 'react';
import moment from 'moment';
import { KTIcon } from '../../../_metronic/helpers';

import { getLeaderBoard } from '../core/userRequest';

import UserDetailsModal from './UserDetailsModal';
import SelectHeader from './SelectHeader';

export default function LeaderBoard() {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState(null);
    const [sortCount, setSortCount] = useState(0);
    const [sortDirection, setSortDirection] = useState('desc');

    const [users, setUsers] = useState([]);
    const [topicId, setTopicId] = useState(null);

    const handleSearchUser = async (topicId) => {
        setTopicId(topicId);
        await getLeaderBoard(topicId)
            .then((res) => {
                setUsers(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const filteredUsers = users.filter((user) =>
        user.user.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
    );

    const sortedUsers = filteredUsers.slice().sort((a, b) => {
        // A'dan Z'ye ve Z'den A'ya sıralama
        if (sortBy === 'user') {
            return a.user.localeCompare(b.user);
        } else if (sortBy === 'score') {
            return a.score - b.score;
        } else if (sortBy === 'date') {
            const dateStringA = moment(a.date, "DD.MM.YYYY HH:mm:ss");
            const dateStringB = moment(b.date, "DD.MM.YYYY HH:mm:ss");
            return new Date(Date.parse(dateStringA)).getTime() - new Date(Date.parse(dateStringB)).getTime();
        } else {
            return 0;
        }
    });

    const handleSort = (key) => {
        if (sortBy === key) {
            setSortCount(sortCount + 1);
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortCount(0);
            setSortDirection("desc");
        }
        setSortBy(key);
    };

    const reversedUsers = sortedUsers;

    if (sortCount % 2 === 1) {
        reversedUsers.reverse();
    }
    return (
        <>
            <SelectHeader onSearch={handleSearchUser} />
            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Liderlik Tablosu</span>
                        {users.length > 0 ? (
                            <span className='text-muted mt-1 fw-semibold fs-7'>Kullanıcı Sayısı {users.length} </span>
                        ) : (
                            <div className="text-center">
                                <span className='text-muted mt-1 fw-semibold fs-7'>Kullanıcı Yok</span>
                            </div>
                        )
                        }
                    </h3>
                    <div className='card-toolbar'>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Kullanıcı Ara..."
                            className='form-control me-5 search-bar'
                        />

                    </div>

                </div>
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive' style={{ maxHeight: "72vh", overflowY: "scroll" }}>
                        {
                            users.length > 0 ? (
                                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                    {/* begin::Table head */}
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='w-25px'></th>
                                            <th className='min-w-150px' onClick={() => handleSort('user')}>
                                                Ad Soyad
                                                {sortBy === 'user' ? (
                                                    sortDirection === 'asc' ? (
                                                        <KTIcon iconName='arrow-down' className='fs-3' />
                                                    ) : (
                                                        <KTIcon iconName='arrow-up' className='fs-3' />
                                                    )
                                                ) : (
                                                    <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                                )}
                                            </th>
                                            <th className='min-w-140px'>
                                            </th>
                                            <th className='min-w-120px'></th>
                                            <th className='min-w-120px' onClick={() => handleSort('score')}>
                                                Skor
                                                {sortBy === 'score' ? (
                                                    sortDirection === 'asc' ? (
                                                        <KTIcon iconName='arrow-down' className='fs-3' />
                                                    ) : (
                                                        <KTIcon iconName='arrow-up' className='fs-3' />
                                                    )
                                                ) : (
                                                    <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                                )}
                                            </th>
                                            <th className='min-w-120px' ></th>
                                            <th className='min-w-120px' onClick={() => handleSort("date")}>Sınava Giriş Tarihi
                                                {sortBy === 'date' ? (
                                                    sortDirection === 'asc' ? (
                                                        <KTIcon iconName='arrow-up' className='fs-3' />
                                                    ) : (
                                                        <KTIcon iconName='arrow-down' className='fs-3' />
                                                    )
                                                ) : (
                                                    <><KTIcon iconName='arrow-up' className='fs-3' /> <KTIcon iconName='arrow-down' className='fs-3' /> </>
                                                )}</th>
                                            <th className='min-w-100px text-end'>İncele</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            sortedUsers.map((item, index) => (
                                                <tr key={index}>
                                                    <td></td>
                                                    <td>
                                                        {item.user}

                                                    </td>
                                                    <td>
                                                        <span className='text-dark fw-bold fs-6'></span>
                                                    </td>
                                                    <td></td>
                                                    <td className='text-dark fw-bold'>
                                                        <span className='text-dark fw-bold d-block mb-1 fs-6'>{item.score}</span>
                                                    </td>
                                                    <td className='text-dark fw-bold text-hover-primary fs-6'>
                                                        <span className='text-dark fw-bold d-block mb-1 fs-6'>

                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span className='text-dark fw-bold d-block mb-1 fs-6'>{item.date}</span>
                                                    </td>
                                                    <td className='text-end'>
                                                        <UserDetailsModal userId={item.user_id} topicId={topicId} />
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            ) : (
                                <div className="text-center mb-5">
                                    <span className='text-muted mt-1 fw-semibold fs-3'>Kullanıcı Yok</span>
                                </div>
                            )
                        }
                    </div>
                    {/* end::Table container */}
                </div>
            </div>
        </>
    );
}