import { useEffect, useState } from 'react';
import { KTSVG, KTIcon } from "../../../_metronic/helpers";
import { useDispatch } from 'react-redux';
import { updateProductThunk } from '../../redux/product/productSlice';


function UpdateProduct(props) {
    const { id, title } = props;
    
    const [value, setValue] = useState(title);
    const dispatch = useDispatch();

    useEffect(() => {
        setValue(title);
    }, [title]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        dispatch(updateProductThunk({ id, title: value }));
        
        setValue('');
    }
    
    return (
        <>
            <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-4'
                type="button"
                data-bs-toggle="modal"
                data-bs-target={`#kt_modal_update_${id}`}
            >
                <KTIcon iconName='pencil' className='fs-3' />

            </button>
            <div className="modal fade" tabIndex={-1} id={`kt_modal_update_${id}`}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Ürün</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <form className="mb-2" onSubmit={handleSubmit}>
                                <label className="form-label text-start d-block">Ürün Adı</label>
                                <input
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                    type="text"
                                    className="form-control"
                                    placeholder="Ürün Adı Giriniz"
                                />
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                onClick={() => setValue('')}
                            >
                                Kapat
                            </button>
                            <button type="button" className="btn btn-primary" onClick={(e) => handleSubmit(e)} data-bs-dismiss="modal" disabled={value === ""}>
                                Güncelle
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateProduct