import FormItem from "./FormItem";

export default function FormRepeater({ formData, setFormData }) {
    const addNewForm = () => {
        const newForm = [...formData, { id: null, title: "", is_correct: 0 }];
        setFormData(newForm);
    }

    const handleDelete = (index) => {
        const newForm = formData.filter((d, i) => i != index);
        setFormData(newForm)
    }

    const handleCheck = (index, value) => {
        const newArray = formData.map((d, i) => {
            if (index == i) {
                return { ...d, is_correct: 1 };
            }
            else {
                return { ...d, is_correct: 0 };
            }
        })

        setFormData(newArray)
    }

    const handleChange = (index, value) => {
        const updatedForm = [...formData];
        updatedForm[index] = { ...updatedForm[index], title: value };
        setFormData(updatedForm);
    }

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="w-100">
                <label className="form-label mb-4 d-block text-start">Şıklar</label>

                <div className={formData.length > 0 ? "item-scroll" : ""}>
                    {
                        formData.map((d, i) =>
                            <FormItem key={i} data={d} index={i}
                                onDelete={handleDelete}
                                onCheck={handleCheck}
                                onChangeInput={handleChange}
                            />
                        )
                    }
                </div>

                <div className="row mt-10">
                    <div className="d-flex justify-content-center alig-items-center">
                        <button className="btn fw-bold btn-primary px-10" onClick={addNewForm} type="button">
                            <i className="bi bi-plus-lg fs-2"></i>Ekle
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}